.navheadingstyle {
   font-weight: 400;
   font-size: 14px;
   color: #000000;

  }

  .navheadingstyle:hover {
    color: #1F2E5E;
    cursor: pointer;
     /* text-decoration: underline; */
  
  
  }

  .marginleft20{
    margin-left: 20px
  }

  .verticalline{
    border-left: 1.5px solid #FFFFFF99;
    height: 14px;
    margin-left: 20px;
  }

  .gk-scr {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.gk-scr::-webkit-scrollbar { 
    display: none; 
}

.react-calendar{
  border: none !important;
}

/* input:focus-visible,
textarea:focus-visible,
select:focus-visible {
  border: 2px solid rgba(255, 255, 255, 0.2) !important;
  outline: none;
} */

.react-calendar{
  width: 100% !important;
}

#notificationHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

#notificationBody {
  margin-top: 10px;
  text-align: center;
}

#imageContainer {
  display: flex;
  align-items: center;
  height: 100px;
  object-fit: contain;
}

.gk-scr {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.gk-scr::-webkit-scrollbar { 
  display: none; 
}

