.horizontalline {
  background: white;
  width: 35px;
  height: 1.5px;
  margin-right: 8px;
}

.wrapper {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 80px;


}

.horizontallinebig {
  background: #0DBE5E;
  width: 255px;
  height: 10px;

  margin-top: -20px;

}

.myshades {
  box-shadow: 1px 1px 2px 2px rgb(255, 255, 255);
}

.myshades2 {
  box-shadow: 7px 7px black;
}

.react-time-picker,
.react-time-picker *,
.react-time-picker *:before,
.react-time-picker *:after {
  border-radius: 8px !important;
  /* margin-left: 10px; */
}

.react-datepicker-wrapper {
  width: 100%;
}

.css-13cymwt-control {
  border: 1px solid #218fdb !important;
  border-radius: 12px !important;
}

[type='text']:focus {
  --tw-ring-shadow: none !important;
}

.download-table-xls-button {
  width: 95%;
  height: 45px;
  margin-top: 20px;
  margin-left: 2.3%;
  display: flex;
  justify-content: flex-end;
}
.download-table-xls-button2 {
  width: 96.5%;
  height: 45px;
  margin-top: 20px;
  margin-left: 2.3%;
  display: flex;
  justify-content: flex-end;
}

.download-text {
  width: 18%;
  height: 45px;
  text-align: center;
  color: white;
  border-radius: 12px !important;
  align-content: center;
}

.download2 {
  width: 76%;
  height: 45px;
}
.toggle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toggle-row {
  display: flex;
  margin-bottom: 20px;
}

.toggle-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
}

.toggle-button.active {
  background-color: #007bff;
  color: white;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

