/* HorizontalDatePicker.css */

.date-picker-container {
  width: 100%;
  padding: 10px 0;
}

.sticky-header {
  background-color: #ffffff;
  padding: 10px;
  font-weight: bold;
  font-size: 1.2em;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.horizontal-date-picker {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
}

.horizontal-date-picker::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}

.date-item {
  flex: none;
  width: 60px;
  height: 60px;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.date-item:hover {
  background-color: #d0d0d0;
}

.date-item.selected {
  background-color: #1F2E5E;
  color: #ffffff;
  font-weight: bold;
}

.day-name {
  font-size: 12px;
}

.day-number {
  font-size: 18px;
  font-weight: bold;
}